var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height(),
    bannerPrev,
    bannerNext,
    bannerPrev1,
    bannerNext1;
    if(!isRTL){
        bannerPrev = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><img src="/assets/img/icon/arrow-back.svg" alt="left" class="toSvg js-tosvg "/></button>',
        bannerNext = '<button class="slick-next slick-arrow" aria-label="Next" type="button"><img src="/assets/img/icon/arrow-forward.svg" alt="right" class="toSvg js-tosvg "/></button>';
    }else{
        bannerPrev = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><img src="/assets/img/icon/arrow-forward.svg" alt="left" class="toSvg js-tosvg "/></button>',
        bannerNext = '<button class="slick-next slick-arrow" aria-label="Next" type="button"><img src="/assets/img/icon/arrow-back.svg" alt="right" class="toSvg js-tosvg "/></button>';
    }
    if(!isRTL){
        bannerPrev1 = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><img src="/assets/img/icon/arrow-back.svg" alt="left" class="toSvg js-tosvg "/></button>',
        bannerNext1 = '<button class="slick-next slick-arrow" aria-label="Next" type="button"><img src="/assets/img/icon/arrow-forward.svg" alt="right" class="toSvg js-tosvg "/></button>';
    }else{
        bannerPrev1 = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><img src="/assets/img/icon/arrow-forward.svg" alt="left" class="toSvg js-tosvg "/></button>',
        bannerNext1 = '<button class="slick-next slick-arrow" aria-label="Next" type="button"><img src="/assets/img/icon/arrow-back.svg" alt="right" class="toSvg js-tosvg "/></button>';
    }

    
$(function() {
    browserDetect();
    initMobSliderNews();
    // initMobSliderCraft();
});

$(window).on('load', function() {
    // Do after Page ready
    doOnLoad();
});

$(window).on('resize orientationchange', function() {
    // Do on resize
    winWidth = $(window).width(),
    winHeight = $(window).height();
    secMinHeight();
    initMobSliderNews();
    // initMobSliderCraft();
    initslidermobPartner();
    getInTouchPopup();
    secMinHeightMblDesk();
    initMobSliderRegister();
    formWrapperMinHeight();
    // licensesSliderInit();
    registerSlider();
    benefitsSlider();
    restrictInputOtherThanArabic($('#first-name-ar'));
    restrictInputOtherThanArabic($('#middle-name-ar'));
    restrictInputOtherThanArabic($('#last-name-ar'));
    
});

$(window).on('scroll', function() {
    //Do on Scroll
    addFixedClass();
});

$(document).keyup(function(e) {
    if (e.keyCode == 27) {
        //Do on ESC press
    }
});

function doOnLoad() {
    // injectVideoTags();
    $('.js-basic-detail-form input, select').trigger('blur');
    bannerSlider();
    secSlider();
    // categorySlider2();
    categorySlider();
    initMobSliderNews();
    // initMobSliderCraft();
    initMobSliderRegister();
    initslidermobPartner()
    ChangeToSvg();
    dataTrim();
    mobileMenu();
    addFixedClass();
    iniIsotope();
    secMinHeight();
    addFixedClass();
    getInTouchPopup();   
    secMinHeightMblDesk(); 
    addFixedClass();
    $(".js-loader").fadeOut(600);
    initSelect2('.js-select2');  
    select2Region();
    attachedFileInit();
    //OnLoad Function
    // setTimeout(function() {
    // }, 4000);
    // chooseTypeChanger();
    chooseTypeInit();
    flatPickr();
    validation('.js-form-validation');
    moreCategoriesInit();
    formWrapperMinHeight();
    dataSrc();
    videoJSPlayer();
    cityOfBirthInput();
    getMaxElementHeight();
    discoverSliderInit();
    registerSlider();
    benefitsSlider();
    gallerySliderInit();
    // licensesSliderInit();
    $('.js-city-container .form-group').removeClass('is--used');
    if($('#region').val() != null && $('#region').val() != ""){
        $('.js-city-container').removeClass('city-container-styling'); 
        // regionSelectOnchage();
    }
    citiesSelect2Init();
    // intlInputInit();
    addFocusClass();
    // setMaxHeghtAtisnalCraft();
    restrictInputOtherThanArabic($('#first-name-ar'));
    restrictInputOtherThanArabic($('#middle-name-ar'));
    restrictInputOtherThanArabic($('#last-name-ar'));
    fancyBoxInit();
    
}


function browserDetect() {
    navigator.sayswho = (function() {
        var ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    $('body').addClass(navigator.sayswho);
}

function addVideoPlugin() {
    if (winWidth > 1024 && $('.js-video').get(0)) {
        var plyrScriptElement = document.createElement("script");
        if (isRTL) {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + '../assets/js/plyr.min.js');
        } else {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + 'assets/js/plyr.min.js');
        }
        plyrScriptElement.setAttribute('async', 'true');
        document.body.appendChild(plyrScriptElement);
    } else {
        jsVideoDirect();
    }
}

var players = [];

function jsVideo() {
    // Custom player
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            var thisParent = $(this).parent();
            players[i] = new Plyr(this, {
                playsinline: true,
                autoplay: false,
            });
            thisParent.find('.plyr').attr('data-video-instance', i);
        });
    }
}

function jsVideoDirect() {
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            $(this).attr('data-video-instance', i);
            var videoId = $(this).attr('data-plyr-embed-id');
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoId + '?rel=0&playsinline=1&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
        });
    }
}

function bannerSlider() {
    $('.js-banner-slider').slick({
        fade:true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        dots:false,
        adaptiveHeight:true,
        autoplaySpeed: 5000,
        pauseOnFocus:false,
        pauseOnHover:false,
        speed: 1000,
        rtl: isRTL,
        infinite: false
    });    
}

function categorySlider2() {
    if ($('.js-category-slider2').length > 0){
        $('.js-category-slider2').each(function(){
            $(this).slick({
                dots: false,
                arrows: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                swipeToSlide: false,
                rtl: isRTL,
                infinite: false,
			    draggable: true,
                prevArrow: bannerPrev,
                nextArrow: bannerNext,
                responsive: [
                    {
                      breakpoint: 1600,
                      settings: {
                        slidesToShow: 4,
                      }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                          slidesToShow: 2,
                        }
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 1,
                          swipeToSlide: false,
                        }
                      },
                  ]
            });    
        })
    }
}
function categorySlider() {
    $('.js-category-slider').slick({
        dots: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1, 
        rtl: isRTL,
        // centerMode: true,
        // autoplay: true
        responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                }
              },
          ]
    });    
}
function secSlider() {
    $('.js-sec-slider').slick({
        dots: false,
        fade: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        adaptiveHeight:true,
        rtl: isRTL,
        
        // autoplay: true
    });    
}
function ChangeToSvg() {
    $('img.js-tosvg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' insvg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });
}

function dataTrim() {
    var ellipsis = "...";
    $('[data-trim]').each(function () {
        var text = $(this).html();
        var charLimit = parseInt($(this).attr('data-trim'));
        $(this).html(TrimLength(text, charLimit));
        $(this).addClass('is--trimmed');
    });

    function TrimLength(text, maxLength) {
        text = $.trim(text);
        if (text.length > maxLength) {
            text = text.substring(0, maxLength - ellipsis.length);
            return text.substring(0, text.lastIndexOf(" ")) + ellipsis;
        } else return text;
    }
}
function initMobSliderNews() {
        if ($('.js-mob-slider').length > 0) {
            $('.js-mob-slider').each(function() {
                $(this).not('.slick-initialized').slick({
                    dots: false,
                    arrows: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    swipeToSlide: true,
                    dots:false,
                    rtl: isRTL,
                    responsive: [
                        {
                            
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                dots:true,
                            }
                        },
                        {
                            
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                dots:true,
                                swipeToSlide: false,
                            }
                        },
                        {
                            
                            breakpoint: 768,
                            settings: {
                                dots:true,
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                
                            }
                        },
                        {
                            
                            breakpoint: 550,
                            settings: {
                                dots:true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ],
                });
            });
        }
    
}
function initMobSliderCraft() {
    if ($('.js-mob-slider-craft').length > 0) {
        $('.js-mob-slider-craft').each(function() {
            $(this).not('.slick-initialized').slick({
                dots: false,
                arrows: false,
                infinite: false,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                variableWidth: false,
                centerMode: false,
                // mobileFirst: true,
                rtl: isRTL,
                responsive: [
                    {
                        
                        breakpoint: 1229,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            dots:true
                        }
                    },
                    {
                        
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots:true,
                            swipeToSlide: false,
                        }
                    },
                    {
                        
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots:true
                        }
                    },
                    {
                        
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots:true
                        }
                    }
                ],
            });
        });
    }

}

function dataSrc() {
    $('[data-video-src]').each(function () {
        var videoSRC = $(this).data('videoSrc');
        $(this).attr('src', videoSRC);
    })
}

function videoJSPlayer() {
    // video-sec
    if($('#my-player').length) {
        var player = videojs('my-player');
        var options = {
            autoplay: true,
        };
        var player = videojs('my-player', options, function onPlayerReady() {
          videojs.log('Your player is ready!');        
          // How about an event listener?
          this.on('ended', function() {
            videojs.log('Awww...over so soon?!');
          });
        });
    
    }
}
function mobileMenu(){
    $('.js-menu-toggle').click(function (){
            $('.header-menu-wraper').toggleClass('open')
            $("body").toggleClass("js-hiden-body")
            $('.header-logo').toggleClass('heder-logo-clr-change');
            $('.js-menu-toggle').toggleClass('active');
            setTimeout(function(){
                 $('.menu-wraper').toggleClass('js-hid') 
                }, 350);  
    })
}
function addFixedClass(){
    let scrollTopValue = $(window).scrollTop();
    if(!$(".header-menu-wraper").hasClass("open") && scrollTopValue > 20 ){
        $('header').addClass('fixed');
    }
    else{
        $('header').removeClass('fixed');
    }
}

function iniIsotope() {
    var filterValue = window.location.hash.split('?')[1];
    if (filterValue !== undefined) {
        $('.button-group button').removeClass('is-checked');
        $(`[data-filter="${filterValue}"]`).addClass('is-checked')
    }
    var $grid = $('.grid').isotope({
        itemSelector: '.js-isotopes',
        filter: filterValue
    });
    $('#filters').on( 'click', 'button', function() {
        var filterValue = $( this ).attr('data-filter');
        $grid.isotope({ filter: filterValue });
    });
    $('.button-group').each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', 'button', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
        });
    });
}
function secMinHeight() {
    if (winWidth > 1023) {
        $('.js-minHeight').each(function (i, e) {
          var $this = $(e),
              minHeight = $(window).outerHeight();
          $this.css('min-height', minHeight + "px");
        });
      }
      else{
        $('.js-minHeight').removeAttr( "style" );
      }
  }
$('input').blur(function(event) {
    var $this = $(event.target);
    $this.parents('.form-group').toggleClass('is--used', $this.val() != '')
})
$('select').blur(function(event) {
    var $this = $(event.target);
    $this.parents('.form-group').toggleClass('is--used', $this.val() != '')
})
// if(!$('.select2-selection__rendered').hasAttr("title")){
    
// }
function validation(target) {
    if ($(target).length) {
        var $target = $(target);
        $target.each(function (i, e) {
            formStepInit($target.eq(i));

            var v = $target.eq(i).validate({
                errorElement: 'span',
                errorClass: "error-txt",
                highlight: function (element) {
                    $(element).parents('.form-group').addClass('invalid-field');
                },
                unhighlight: function (element) {
                    $(element).parents('.form-group').removeClass('invalid-field');
                },
                invalidHandler: function (form, validator, element) {
                    var errors = validator.numberOfInvalids();
                    if (errors) {
                        $(element).parents('.form-group').addClass('invalid-field');
                    } else {
                        $target.find(".error-msg").hide();
                    }
                },
                submitHandler: function (form, validator) {
                    formSubmit(form);
                }
            });
        });
    }
}

$.validator.addMethod('validEmail', value => /^([A-Za-z0-9_\-\.\%\$\&\'\(\)\*\+/])+\@([A-Za-z0-9_\-\.\%\$\&\'\(\)\*\+/])+\.([a-z]{2,4})$/gi.test(value));
$.validator.addMethod('validUrl', function(value, element) {
    var url = $.validator.methods.url.bind(this);
    return url(value, element) || url('http://' + value, element);
});
$.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
});
// $.validator.addMethod('validEmirateID', value => /^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$/.test(value));
//var validateEmiratesIdInti = validateEmiratesId($('#emiratesIdNumber'));
$.validator.addMethod("validEmirateID", function (value, element) {
    return validateEmiratesId(value);
});
//  $( ".js-select2").select2();
function validateEmiratesId(field) {
    let valueEmiratesId= field;//field.val();
    let fixedValueEmiratesId = valueEmiratesId.replace(/-/g, "")
    var EmiratesIdValidationMessage = "message";
    if (fixedValueEmiratesId.match("^784") == null) {
        // return EmiratesIdValidationMessage;
        console.log("784")
        return false;
    } else {
        console.log("else")
        var checknumber = false;
        var firstNumber = 0;
        var secondNumber = 0;
        var numberarray = fixedValueEmiratesId;

        var lastDigit = numberarray[numberarray.length - 1];


        for (var i = numberarray.length - 2; i >= 0; i--) {
            if (i % 2 == 1) {
                var value = Math.floor(numberarray[i] * 2);
                if (value > 9) {
                    var sum = 0;
                    var num = value;
                    while (num != 0) {
                        var r = Math.floor(num % 10);
                        num = Math.floor(num / 10);
                        sum = Math.floor(parseInt(sum) + parseInt(r));
                    }
                    value = sum;
                }


                firstNumber = Math.floor(parseInt(firstNumber) + parseInt(value));

            }
            else {
                value = numberarray[i];

                secondNumber = Math.floor(parseInt(secondNumber) + parseInt(value));

            }
        }


        var total = Math.floor(parseInt(firstNumber) + parseInt(secondNumber));


        var hnumber = Math.floor(total % 10);

        var result;

        if (hnumber == 0) {

            result = 0;
        }
        else {
            result = Math.floor(10 - hnumber);
        }
        if (lastDigit - result != 0) {
            // return EmiratesIdValidationMessage;
            console.log("lastDigit - result != 0 if")
            return false;
        }else{
            console.log("lastDigit - result != 0 else")
            return true;
        }

    }
}



var selprepend;
function initSelect2(target, search) {
    var $target = $(target);
    $target.each(function (i, e) {
        var tarPar = $(e).parent('.select2-box');
        var tarPlace = $(e).data('placeholder');
        var dataClass = $(e).data('class');
        // var searchVal = search;
        // searchVal = $(e).hasClass('js-search') ? $(e).attr('data-search') : search ;
        $(e).select2({
            dropdownParent: tarPar,
            // minimumResultsForSearch: -1,
            width: '100%',
            placeholder: tarPlace,
            dir: isRTL,
        }).on('select2:open', function (event, con) {
            $('.select2-results__options').scrollbar().parent().addClass('scrollbar-inner');
            const $thisSelect = $(event.currentTarget).parent();
            setTimeout(function(){
                $thisSelect.find('.select2-search__field')[0].focus();
            }, 100)
        }).on('select2:select select2:unselect', function (evt) {
            var uldiv = $(e).siblings('span.select2').find('ul');
            var count = $(e).select2('data').length;
            tarPar.addClass('is--selcted');            
            if (count == 0) {
                uldiv.html("<li>" + tarPlace + "</li>");
            } else {
                uldiv.html("<li>" + tarPlace + " (" + (count) + ")</li>")
                $('.js-selAll').removeClass('is--selected');
                var oplenght = $(e).parent().find('li.select2-results__option').length;
                if (oplenght == count) {
                    $('.js-selAll').addClass('is--selected');
                }
            }
            $(evt.target).valid();
        });;
    });
}
function select2Region(){
    var tarPar = $('.js-select-region').parent('.select2-box');
    var tarPlace = $('.js-select-region').data('placeholder');
    $('.js-select-region').select2({
        dropdownParent: tarPar,
        minimumResultsForSearch: -1,
        width: '100%',
        placeholder: tarPlace,
        dir: isRTL,
    }).on('select2:open', function () {
        $('.select2-results__options').scrollbar().parent().addClass('scrollbar-inner');
    }).on('select2:select select2:unselect', function (evt) {
        var uldiv = $('.js-select-region').siblings('span.select2').find('ul');
        var count = $('.js-select-region').select2('data').length;
        tarPar.addClass('is--selcted');
        $('.js-city-container').removeClass('city-container-styling'); 
        // regionSelectOnchage();
        if (count == 0) {
            uldiv.html("<li>" + tarPlace + "</li>");
        } else {
            uldiv.html("<li>" + tarPlace + " (" + (count) + ")</li>")
            $('.js-selAll').removeClass('is--selected');
            var oplenght = $('.js-select-region').parent().find('li.select2-results__option').length;
            if (oplenght == count) {
                $('.js-selAll').addClass('is--selected');
            }
        }
        $(evt.target).valid();
    });
}
function selectRegion(){
    let selectRegionValue = $('#region').val();
    if(selectRegionValue == 1){
    }else if(selectRegionValue == 2){
    }
    else if(selectRegionValue == 3){
    }else{
    }
}
$('.phone-mask').mask('00-000-0000');
$('.emirates-id-num-mask').mask('000-0000-0000000-0');
$('.passport-num-mask').mask('000000000');
$('.po-num-mask').mask('000000000');

function flatPickr() {
    var currentDate = new Date();
    $(".js-birthday-picker").flatpickr({
        dateFormat: "d-m-Y",
        disableMobile: true,
        maxDate: currentDate,
        onChange: function(selectedDates, dateStr, instance) {
            $(instance.element).valid();
        },
    });
    $(".js-flat-picker").flatpickr({
        dateFormat: "d-m-Y",
        disableMobile: true,
        onChange: function(selectedDates, dateStr, instance) {
            $(instance.element).valid();
        },
    });
    $(".js-pasportIssue-picker").flatpickr({
        dateFormat: "d-m-Y",
        disableMobile: true,
        maxDate: currentDate,
        onChange: function(selectedDates, dateStr, instance) {
            $(instance.element).valid();
        },
    });
    $(".js-pasportExpiry-picker").flatpickr({
        dateFormat: "d-m-Y",
        disableMobile: true,
        minDate: currentDate,
        onChange: function(selectedDates, dateStr, instance) {
            $(instance.element).valid();
        },
    });
    $(".js-idExpiry-picker").flatpickr({
        dateFormat: "d-m-Y",
        disableMobile: true,
        minDate: currentDate,
        onChange: function(selectedDates, dateStr, instance) {
            $(instance.element).valid();
        },
    });
}


function attachedFileInit(){
    $('input[type="file"]').each(function() {
        // get label text
        var label = $(this).parents('.form-group').find('label').text();
        label = (label) ? label : '';
    
        // wrap the file input
        $(this).wrap('<div class="input-file"></div>');
        // display label
        $(this).before('<span class="btn-12">'+label+'</span>');
        // we will display selected file here
        $(this).before('<span class="file-selected">File size less than 4 mb <span> (حجم الملف أقل من 4 ميجابايت)</span></span>');
    
        // file input change listener 
        $(this).change(function(e){
            // Get this file input value
            var val = $(this).val();
            // Let's only show filename.
            // By default file input value is a fullpath, something like 
            // C:\fakepath\Nuriootpa1.jpg depending on your browser.
            var filename = val.replace(/^.*[\\\/]/, '');
            // Display the filename
            if(!(this.files[0].size > $(this).data('ruleFilesize'))){
                $(this).siblings('.file-selected').text(filename);
            }else{
                $(this).siblings('.file-selected').text($(this).data('msgFilesize'));
            }
            $(this).valid();
        });
    });
    // Open the file browser when our custom button is clicked.
    $('.input-file .btn-12').click(function() {
        $(this).siblings('input[type="file"]').trigger('click');
    });
}

function initMobSliderRegister() {
    if ($('.js-mob-slider-register').length > 0) {
        $('.js-mob-slider-register').each(function() {
            $(this).not('.slick-initialized').slick({
                dots: false,
                arrows: false,
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                variableWidth: false,
                centerMode: false,
                rtl: isRTL,
                responsive: [
                    {
                        
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ],
            });
        });
    }

}

function getInTouchPopup(){
    
  let $registerBtn = $('.js-getInTouch-open')
  let $registerBox = $('.js-getInTouch-mian-wrapper')
  let $registerBtnClose = $('.js-getInTouch-closed')
  $registerBtn.on('click', () => {
      $registerBox.addClass('showing');
      if (winWidth > 1023) {
          $('body').addClass('hide-scroll');
      }
  });
  $registerBtnClose.on('click', () => {
      $registerBox.removeClass('showing');
      if (winWidth > 1023) {
          $('body').removeClass('hide-scroll');
      }
  });
}

function secMinHeightMblDesk() {
  $('.js-minHeight-2').each(function (i, e) {
      var $this = $(e),
          minHeight = $(window).outerHeight();
      $this.css('min-height', minHeight + "px");
    });
}

function chooseTypeChanger() {
    $('.craft-button-first-step [name="type"]').on('change', function(event) {
        var targetValue = event.target.value;
        $('.js-choose-type').addClass('d-none');
        $(`.js-choose-type[data-name=${targetValue}]`).removeClass('d-none');
    })
}

function chooseTypeInit() {
    var targetValue1 = $('.craft-button-first-step [name="type"]:checked').val();
    $('.js-choose-type').addClass('d-none');
    $(`.js-choose-type[data-name=${targetValue1}]`).removeClass('d-none');
    chooseTypeChanger();
}
function removeLanguageChangerFromForm() {
    if($( ".basic-detail-form-en-ar" ).hasClass( "step--active" )){
        $('.arbic-link-form').addClass('d-none');
        $('.en-font').addClass('d-none');
        $('.ar-font').addClass('d-none');
    }else{
        $('.arbic-link-form').removeClass('d-none');
        $('.en-font').removeClass('d-none');
        $('.ar-font').removeClass('d-none');
    }   
}
function formStepInit(vform){
    let submitContinueBtn = $('.js-submit-continue-btn');
    let backBtn = $('.js-back-form-btn');
    var currentStep = 0;
    var totalStep = 25;
    var totalSpan = 0;
    // add attribut
    $(".form-steps-wrapper" ).each(function( index) {
        $(this).attr('data-step-index', index);
        totalStep +=1        
    });

    $(vform).attr('data-current-step', currentStep);

    submitContinueBtn.on('click', function() {
        if (vform.valid() && totalStep - 1 > currentStep) {
            $(`[data-step-index = ${$(vform).attr('data-current-step')}]`).removeClass('step--active');
            $(`[data-step-index = ${parseInt($(vform).attr('data-current-step')) + 1}]`).addClass('step--active')
            currentStep += 1;
            totalSpan += 33.33;
            $('.js-step-bar').css('width', `${totalSpan}%` )
            $(vform).attr('data-current-step', currentStep);
            chooseTypeChanger();
            setMaxHeghtAtisnalCraft();
            removeLanguageChangerFromForm();
        }
    });

    backBtn.on('click', function() {
        $(`[data-step-index = ${$(vform).attr('data-current-step')}]`).removeClass('step--active');
        $(`[data-step-index = ${parseInt($(vform).attr('data-current-step')) - 1}]`).addClass('step--active');
        currentStep -= 1;
        totalSpan -= 33.33;
        if(totalSpan > 25){
            $('.js-step-bar').css('width', `${totalSpan}%`)

        }else{
            $('.js-step-bar').css('width', `25%`)
        }
        $(vform).attr('data-current-step', currentStep);
        chooseTypeChanger();
        setMaxHeghtAtisnalCraft();
        removeLanguageChangerFromForm()
    })

}
function moreCategoriesInit() {
    var radioBtnBox = $('.js-more-categories-wrapper .custom-card-radio')
    radioBtnBox.each((index ,e) =>{
        if(index > 8){
            $(e).css('display', 'none');
            $('.js-more-categories').css('display', 'block')
        }
        $('.js-more-categories').on('click', () =>{
            $(e).css('display', 'block')
            $('.js-more-categories').css('display', 'none')
        })
    })
}
function formWrapperMinHeight() {
    var formHeaderHeight = $('.js-header-height').outerHeight();
    $('.js-min-height-steps-wrapper').each(function (i, e) {
        var $this = $(e),
            minHeight = $(window).outerHeight();
            totalHeightSec = minHeight - formHeaderHeight
        $this.css('min-height', totalHeightSec + "px");
        });
}
function cityOfBirthInput() {
    $('#country-of-birth').on('select2:select', (e) => {
        var data = e.params.data.id;
        if (data === 'UAE') {
            $('.js-emirate-of-birth').removeClass('d-none');
        }else{
            $('.js-emirate-of-birth').addClass('d-none');
        }
    })
}
// mobile slider

function initslidermobPartner() {
    if ($('.js-mob-slider-partner').length > 0) {
        if (winWidth < 768) {
            if (!$('.js-mob-slider-partner').hasClass('slick-initialized')) {
                $('.js-mob-slider-partner').slick({
                    dots: true,
                    arrows: false,
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    rtl: isRTL,
                    responsive: [
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }
        } else {
            if ($('.js-mob-slider-partner').hasClass('slick-initialized')) {
                $('.js-mob-slider-partner.slick-initialized').each(function () {
                    $(this).slick('unslick');
                });
            }
        }
    }
};
function getMaxElementHeight() {
    var heights = $('.custom-card-radio.style--small').map(function ()
    {
        return $(this).height();
    }).get();

    return Math.max.apply(null, heights);
}
function setMaxHeghtAtisnalCraft() {
    $('.custom-card-radio.style--small').css('opacity', 0);
    setTimeout(function(){
        $('.custom-card-radio.style--small').css('opacity', 1);
    }, 100)
    $('.custom-card-radio.style--small label').css('min-height', 0 + "px");
    var maxHeightContetnt = getMaxElementHeight();
    $('.custom-card-radio.style--small label').each(function(i,e) {
        $(e).css('min-height', maxHeightContetnt + "px");
    })
}

function regionSelectOnchage(){
    let cities = [
        {
            region: "Abu Dhabi",
            regionCities: [
                {
                    id: 1,
                    name: 'Al Rahbah'
                },
                {
                    id: 2,
                    name: 'Al Shamkha'
                },
                {
                    id: 3,
                    name: 'Al Shahamah'
                },
                {
                    id: 4,
                    name: 'Al Falah'
                },
                {
                    id: 5,
                    name: 'Al Bahiyh'
                },
                {
                    id: 6,
                    name: 'Al Shahamah'
                },
                {
                    id: 7,
                    name: 'Al Wathba'
                },
                {
                    id: 8,
                    name: 'Bani Yas'
                },
                {
                    id: 9,
                    name: 'Other'
                }
            ]
        },
        {
            region: "Al Ain",
            regionCities: [
                {
                    id: 10,
                    name: 'Al-Qu’a'
                },
                {
                    id: 11,
                    name: 'Al Wegan'
                },
                {
                    id: 12,
                    name: 'Al Shwaib'
                },
                {
                    id: 13,
                    name: 'Al Hayer'
                },
                {
                    id: 14,
                    name: 'Al Muwaiji'
                },
                {
                    id: 15,
                    name: 'Al Maqam'
                },
                {
                    id: 16,
                    name: 'Alealia'
                },
                {
                    id: 17,
                    name: 'Alsaad'
                },
                {
                    id: 18,
                    name: 'Nahel'
                },
                {
                    id: 19,
                    name: 'Remah'
                },
                {
                    id: 20,
                    name: 'Swehan'
                },
                {
                    id: 21,
                    name: 'Um ghafa'
                },
                {
                    id: 22,
                    name: 'Other'
                }
            ]
        },
        {
            region: "Al Dhafra",
            regionCities: [
                {
                    id: 23,
                    name: 'Al Mirfa'
                },
                {
                    id: 24,
                    name: 'Dalma'
                },
                {
                    id: 25,
                    name: 'Ghayathi'
                },
                {
                    id: 26,
                    name: 'Ghwifat'
                },
                {
                    id: 27,
                    name: 'Liwa'
                },
                {
                    id: 28,
                    name: 'Madinat Zayed'
                },
                {
                    id: 29,
                    name: 'Ruwais'
                },
                {
                    id: 30,
                    name: 'Slia'
                },
                {
                    id: 31,
                    name: 'Other'
                }
            ]
        }
    ]
    // $('.js-select-cites').addClass('d-none'); 
    citiesSelect2Init();
    let selectRegionValue =  $('#region').select2('data');
    let selectRegionValueText = selectRegionValue[0].text;
    let aotion1 = document.createElement('option')
    $('.js-select2-box-city select').append(aotion1)
    $('.js-select2-box-city').removeClass('is--selcted');
    $('.js-city-container .form-group').removeClass('is--used');
    const appendOptionValue = cities.map((inner) => {
        if(inner.region === selectRegionValueText) {
            const region_array = inner.regionCities.map(inner_item => {
               let  option = document.createElement('option')
                option.text= inner_item.name;
                option.setAttribute('value', inner_item.id)
                $('.js-select2-box-city select').append(option)
            })
            
        }
    })  
      
}
function citiesSelect2Destroy(){
    $('.js-select-cites').select2('destroy'); 
    
}
function citiesSelect2Init(){
    $('.js-select2-box-city select').html(" ");
    var tarPar = $('.js-select-cites').parent('.select2-box');
    var tarPlace = $('.js-select-cites').data('placeholder');
    $('.js-select-cites').select2({
        dropdownParent: tarPar,
        // minimumResultsForSearch: -1,
        width: '100%',
        placeholder: tarPlace,
        dir: isRTL,
    }).on('select2:open', function (event, con) {
        $('.select2-results__options').scrollbar().parent().addClass('scrollbar-inner');
        const $thisSelect = $(event.currentTarget).parent();
        setTimeout(function(){
            $thisSelect.find('.select2-search__field')[0].focus();
        }, 100)
    }).on('select2:select select2:unselect', function (evt) {
        var uldiv = $('.js-select-cites').siblings('span.select2').find('ul');
        var count = $('.js-select-cites').select2('data').length;
        tarPar.addClass('is--selcted');
        if (count == 0) {
            uldiv.html("<li>" + tarPlace + "</li>");
        } else {
            uldiv.html("<li>" + tarPlace + " (" + (count) + ")</li>")
            $('.js-selAll').removeClass('is--selected');
            var oplenght = $('.js-select-cites').parent().find('li.select2-results__option').length;
            if (oplenght == count) {
                $('.js-selAll').addClass('is--selected');
            }
        }
        $(evt.target).valid();
    });
    
}

function intlInputInit(){
    if ($('#phone-number').length) {
        var utilsPath = 'assets/js/utils.js';
        var inputphone = document.querySelector("#phone-number");
        var inputphoneValue = window.intlTelInput(inputphone, {
            initialCountry: "ae",
            separateDialCode: true,
            dropdownContainer: document.body,
            utilsScript: utilsPath,
            rtl: true
        });
    }
}

function addFocusClass(){
    $( ".phone-number-container .form-control" ).focus(function() {
        $( ".phone-number-container .form-group" ).addClass('is--used');
    });
    $( ".phone-number-container .form-control" ).blur(function() {
        $( ".phone-number-container .form-group" ).addClass('is--used');
    });
}
function restrictInputOtherThanArabic($field)
        {
          // Arabic characters fall in the Unicode range 0600 - 06FF
          var arabicCharUnicodeRange = /[\u0600-\u06FF]/;

          $field.bind("keypress", function(event)
          {
            var key = event.which;
            // 0 = numpad
            // 8 = backspace
            // 32 = space
            if (key==8 || key==0 || key === 32)
            {
              return true;
            }

            var str = String.fromCharCode(key);
            if ( arabicCharUnicodeRange.test(str) )
            {
              return true;
            }

            return false;
          });
}

//ADRA 1.2 

function discoverSliderInit() {
    // if ($('.js-discovery-slider1').length){
        $('.js-discovery-slider1').slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            pauseOnHover: true,
            autoplay: false,
            autoplaySpeed: 6000,
            rtl: isRTL,
            infinite: true,
            draggable: true,
            centerMode: false,
            appendArrows: $('.js-discovery-slider1').next('.js-discovery-slider1-arrows'),
            prevArrow: bannerPrev1,
            nextArrow: bannerNext1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                    }
                }
            ]

          });
        //   $('.js-discovery-slider-nav').slick({
        //         dots: false,
        //         arrows: false,
        //         slidesToShow: 2,
        //         slidesToScroll: 1,
        //         swipeToSlide: false,
        //         centerMode: false,
        //         rtl: isRTL,
        //         infinite: true,
		// 	    draggable: true,
        //         asNavFor: '.js-discovery-slider-for',
        //         responsive: [
        //             {
        //               breakpoint: 1400,
        //               settings: {
        //                 slidesToShow: 2,
        //                 slidesToScroll: 1,
        //               }
        //             },
        //             {
        //               breakpoint: 768,
        //               settings: {
        //                 slidesToShow: 1,
        //                 slidesToScroll: 1
        //               }
        //             }
        //           ]
        //   });
    }
// }

function gallerySliderInit() {
    if ($('.js-gallery-slider-wrapper').length){
        var sliderIndicator = $('.js-gallery-slider-wrapper').find('.js-slickIndicator');//$('.js-slickIndicator')
        $('.js-gallery-slider').on('init', function(event, slick) {
            curSlide = slick.currentSlide + 1;
            totalSlide = slick.slideCount;
            getPrecentage = (curSlide / totalSlide) * 100;
            sliderIndicator.find('.slick-progress').find('span').width(getPrecentage + '%');
        });
          $('.js-gallery-slider').slick({
                dots: true,
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                swipeToSlide: false,
                // centerMode: false,
                pauseOnHover: true,
                autoplay: true,
                autoplaySpeed: 6000,
                rtl: isRTL,
                infinite: true,
			    draggable: true,
                appendDots: $('.slider-navigation').find('.slidenumbers'),
                customPaging: function (slider, i) {
                    var tCount = slider.slideCount;
                    var slideCount =  (i + 1);
                    if (i < 9) {
                        slideCount = '0' + slideCount 
                       
                    }
                    if(tCount < 10)
                    {
                        tCount = '0' + tCount;
                    }
                    return slideCount + '/<small> '+ tCount + '<small>';
                },
                responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                      }
                    },
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                  ]
          });
          $('.js-gallery-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            curSlide = nextSlide + 1;
            totalSlide = slick.slideCount;
            getPrecentage = (curSlide / totalSlide) * 100;
            sliderIndicator.find('.slick-progress').find('span').width(getPrecentage + '%');
        });
    }
}
function fancyBoxInit(){
    $('[data-fancybox]').fancybox({
        buttons: [
            'thumbs',
            'close'
        ],
        infobar: false,

    });
    $('[data-fancybox="discoveryVideo"]').fancybox({
        buttons: [
            'thumbs',
            'close'
        ],
        infobar: false,

    });
}

function licensesSliderInit(target) {
    if ($('.js-licenses-slider-wrapper').length){
        var sliderIndicator = $('.js-licenses-slider-wrapper').find('.js-slickIndicator');//$('.js-slickIndicator')
        $(target).on('init', function(event, slick) {
            curSlide = slick.currentSlide + 1;
            totalSlide = slick.slideCount;
            getPrecentage = (curSlide / totalSlide) * 100;
            sliderIndicator.find('.slick-progress').find('span').width(getPrecentage + '%');
        });
          $(target).slick({
                dots: true,
                arrows: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                swipeToSlide: true,
                // centerMode: false,
                pauseOnHover: true,
                autoplay: false,
                autoplaySpeed: 6000,
                rtl: isRTL,
                infinite: true,
			    draggable: true,
                centerMode: false,
                appendDots: $('.slider-navigation').find('.slidenumbers'),
                customPaging: function (slider, i) {
                    return '0' + (i + 1) + '/<small> ' + '0' + slider.slideCount + '<small>';
                },
                responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                      }
                    },
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                  ]
          });
          $(target).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            curSlide = nextSlide + 1;
            totalSlide = slick.slideCount;
            getPrecentage = (curSlide / totalSlide) * 100;
            sliderIndicator.find('.slick-progress').find('span').width(getPrecentage + '%');
        });
    }
}
$('body').on('click', '.dropdown-btn', function(e) {
    e.preventDefault();
    var currentContext = $(this).attr('href')
    var barwrapperParent = $(this).parents('.bar-wrapper');
    var barwrapperbtn = $(this).attr('href');
    if($(currentContext).hasClass('active')){
        $(barwrapperParent).removeClass('isActive');
        $(barwrapperbtn).removeClass('isActive');
        $(currentContext).hide();
        $(currentContext).removeClass('active'); 
    } else {
        $('.bar-wrapper').removeClass('isActive')
        $('.liscence-detail-box').hide();
        $('.liscence-detail-box').removeClass('active');
        $(barwrapperParent).addClass('isActive');
        $(barwrapperbtn).addClass('isActive');
        $(currentContext).show();
        $(currentContext).addClass('active');
        if(!$(currentContext).hasClass('slick-active')){
            $(currentContext).addClass('slick-active');
            var targetslider = $(currentContext).find('.js-licenses-slider')
            setTimeout(function() {
                licensesSliderInit(targetslider);
            }, 50);
        }
    }
});
jQuery(document).ready(function(){
    $('body').on('click', '.dropdown-btn', function(e) {
        $('.dropdown-btn').removeClass('btnActive');
        $(this).addClass('btnActive');   
        e.preventDefault();
    });
});
function registerSlider() {
    $('.js-registerSlider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        swipeToSlide: true,
        pauseOnHover: true,
        autoplay: false,
        autoplaySpeed: 6000,
        rtl: isRTL,
        infinite: false,
        draggable: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    });
}
function benefitsSlider() {
    $('.js-benefitsSlider').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        pauseOnHover: true,
        autoplay: false,
        autoplaySpeed: 6000,
        rtl: isRTL,
        infinite: true,
        draggable: true,
        centerMode: false,
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: "unslick"
            }
          ]
    });
}
// fancy box
function galleryFancyBox() {
    $(document).ready(function() {
        $(".fancybox-gallery").each(function(i,e){
            $(e).fancybox({
                prevEffect		: 'none',
                nextEffect		: 'none',
                closeBtn		: false,
                helpers		: {
                    title	: { type : 'inside' },
                }
            });
        })
    });
}
// fancy box